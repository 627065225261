const FooterSVG = (props: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div className="w-full h-full bg-fixed bg-center bg-cover bg-no-repeat"
      // style={{
      //   backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 400"><rect fill="%23EB332C" width="800" height="400"/><defs><radialGradient id="a" cx="396" cy="281" r="514" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%23EB532C"/><stop offset="1" stop-color="%23EB332C"/></radialGradient><linearGradient id="b" gradientUnits="userSpaceOnUse" x1="400" y1="148" x2="400" y2="333"><stop offset="0" stop-color="%23FA3" stop-opacity="0"/><stop offset="1" stop-color="%23FA3" stop-opacity="0.5"/></linearGradient></defs><rect fill="url(%23a)" width="800" height="400"/><g fill-opacity="0.5"><circle fill="url(%23b)" cx="267.5" cy="61" r="300"/><circle fill="url(%23b)" cx="532.5" cy="61" r="300"/><circle fill="url(%23b)" cx="400" cy="30" r="300"/></g></svg>')`
      // }}
    >
      {props.children}
    </div>
  );
}

export default FooterSVG;