import { FooterLinksList } from "@/config/footer-links";

const FooterLinks = () => {

  const menuDivs = [];
  for (let i = 0; i < FooterLinksList.length; i++) {

    for (let key in FooterLinksList[i]) {

      const menuInnerArray = [];

      for (let j = 0; j < FooterLinksList[i][key].length; j++) {
        let xtraProps: any = {};
        if(FooterLinksList[i][key][j].xtraProps){
          xtraProps = FooterLinksList[i][key][j].xtraProps;
        }

        const menuItem = (
          <li className="pl-0 py-0.5" key={j.toString()}>
            <a className="link  font-light" href={FooterLinksList[i][key][j].link} {...xtraProps}>{FooterLinksList[i][key][j].title}</a>
          </li>
        );
        menuInnerArray.push(menuItem);
      }


      // menuInnerArray=[{<li><a href="">sdff</a></li>},{<li><a href="">sdff</a></li>}]

      const menuDiv = (
        <section key={key.toString()} className="mb-6 break-inside-avoid-column text-left">
          <h6 className="uppercase font-bold mb-3 ">{key}</h6>
          <ul className="text-sm pl-0 list-none list-inside">
            {menuInnerArray}
          </ul>
        </section>
      );
      menuDivs.push(menuDiv);
    }

  }
  return (
    <div className="columns-3xs gap-8 space-y-8">
      {menuDivs}
    </div>)

}
export default FooterLinks;